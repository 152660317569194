import SEO from "../components/SEO";
import React, { useEffect } from "react";
import GlobalStyle from "../components/GlobalStyle";
import Nav from "../components/Nav";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import AppStoreLinks from "../components/AppStoreLinks";
import Analytics from "../utils/tracking";

const WeworkPage = () => {

  useEffect(() => {
    ;(async () => {
      await Analytics.track("Wework x Stockperks Landing Page")
    })()
  }, [])


  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "stockperks.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      companyHero: file(relativePath: { eq: "clients/wework-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        title="Stockperks x Wework"
        url={"/wework"}
      />
      <GlobalStyle />
      <Nav siteTitle={"Stockperks x Wework"} />
      <PageContent>
        <Header>
          <a href={"https://www.stockperks.com"}>
            <CompanyLogo fluid={data.logo.childImageSharp.fluid} />
          </a>
          <Title>WeWork is now on Stockperks!</Title>
        </Header>
        <Body>
          <CompanyHero fluid={data.companyHero.childImageSharp.fluid} />
          <Content>
            <Paragraph>
              With the purchase of just one WeWork Inc. share (NYSE:WE), the company is offering three (3) complimentary WeWork On Demand day passes for workspaces in select WeWork locations (as indicated on the WeWork On Demand app) between now and the end of the year. Terms apply*
            </Paragraph>
            <Paragraph>
              To validate your eligibility and redeem your perk, please visit the Apple App Store or Google Play Store and download the free Stockperks app.
            </Paragraph>
            <VSpace/>
            <AppStoreLinks />
            <Paragraph>
              Once you create a profile on the Stockperks app, you will be able to discover and redeem your perks. Stay tuned for more perks in the future.
            </Paragraph>
          </Content>
        </Body>
        <Footer>
          <Content>
          <SmallParagraph>
            *WeWork On Demand Shareholder 3 Day Pass Terms and Conditions: This offer (“Offer”) is valid
            for new WeWork On Demand members who are the beneficial owners of at least one (1) WeWork
            Inc. share of Class A common stock. Offer participants will receive three (3) free day passes for
            workspace bookings at select WeWork locations as indicated in the WeWork On Demand app only;
            subject to availability, booking and the <a href={"https://www.wework.com/legal/on-demand-membership-terms"} target={"_blank"}>WeWork On Demand Membership Terms and Conditions</a>.  Offer
            is valid beginning on October 21, 2021 and Offer promotional code must be redeemed by December
            31, 2021 ("Redemption Period"). Participants must enter the unique promotional code provided
            during the checkout process of WeWork On Demand app; cannot be redeemed on WeWork.com.
            Limit three (3) day passes per individual and the promotional code may only be used three (3) times
            and participants must not be an existing WeWork On Demand user. Offer available for individuals
            primarily located in the United States and Canada (specifically excluding Quebec). Offer cannot be
            applied to previous purchases, is non-transferrable, has no cash value and cannot be redeemed for
            cash or combined with any other offer. WeWork may modify or terminate the Offer at any time
            without notice
          </SmallParagraph>
          </Content>
        </Footer>
      </PageContent>
    </>
  )
}

export default WeworkPage

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-top: 95px;
`;

const CompanyLogo = styled(Img)`
  width: 300px;
  height: auto;
`;

const CompanyHero = styled(Img)`
  max-width: 1000px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
`

const Header = styled.div`
  display: flex;
  height: 140px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-family: Arial, Helvetica, serif;
  font-size: 21px;
  font-weight: 700;
  color: black;
`

const Body = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  padding: 25px;
`;

const Paragraph = styled.p`
  font-family: Arial, Helvetica, serif;
  font-size: 18px;
  color: #7B7B7B;
  text-align: center;
`

const SmallParagraph = styled.p`
  font-family: Arial, Helvetica, serif;
  font-size: 11px;
  color: #9C9C9C;
  text-align: center;
`

const VSpace = styled.div`
  width: 100%;
  height: 25px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 45px;
  max-height: 300px;
  background-color: #f2f2f2;
  padding-bottom: 63px;
  font-size: 12px;
  word-break: break-word;
  margin-top: 95px;
  width: 100%;
`